<template>
    <v-row>
        <v-col>
            <v-row v-if="!isLoggedIn" class="mt-10">
                <v-col>
                    <v-card
                        class="mx-auto"
                        max-width="480"
                        min-height="300"
                        color="appBackground"
                        flat
                    >
                        <v-card-title>
                            <h3 class="word__wrap text-center">
                                {{ $t(`MyProjects.notLogged`) }}
                            </h3>
                        </v-card-title>
                        <v-card-text
                            class="d-flex justify-center flex-column align-center"
                        >
                            <hero animation-is="" :size="200"></hero>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn
                                depressed
                                x-large
                                color="primary"
                                @click="register()"
                            >
                                {{ $t(`project.newProject.registerBtn`) }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row
                v-else-if="getProjectsStatus_Success"
                class="mt-2 my-projects"
            >
                <v-col cols="12">
                    <h1>{{ $t(`MyProjects.heading`) }}</h1>
                </v-col>
                <v-col cols="12">
                    <v-tabs v-model="tab" background-color="appBackground">
                        <v-tab v-for="item in tabItems" :key="item.id">
                            {{ $t(`MyProjects.tabs.${item.title}`) }}
                        </v-tab>

                        <v-tab-item>
                            <v-row>
                                <v-col
                                    v-for="(project,
                                    index) in publishedProjects"
                                    :key="index"
                                    cols="12"
                                    md="6"
                                    lg="4"
                                    class="mt-6"
                                >
                                    <project-info-card
                                        :project="project"
                                        :type="getProjectType(project)"
                                        card-width="372"
                                        :card-heigth="cardHeight"
                                        :media-height="mediaHeight"
                                        media-width="372"
                                        :thumbnail="
                                            project.youtube_url ? true : false
                                        "
                                        @card-click="viewProject(project)"
                                    >
                                        <template #deleteProject>
                                            <delete-project-btn
                                                :project="project"
                                                :type="getProjectType(project)"
                                                @get-projects="
                                                    handleMyProjects()
                                                "
                                            ></delete-project-btn>
                                        </template>
                                        <template #editProject>
                                            <edit-project-btn
                                                :project="project"
                                            ></edit-project-btn>
                                        </template>
                                        <template #viewAction>
                                            <view-project-btn
                                                @view-project="
                                                    viewProject(project)
                                                "
                                            ></view-project-btn>
                                        </template>
                                    </project-info-card>
                                </v-col>
                                <v-col
                                    v-if="publishedProjects.length <= 0"
                                    cols="12"
                                    class="mt-8"
                                >
                                    {{ $t("MyProjects.tabs.noPublished") }}
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item>
                            <v-row>
                                <v-col
                                    v-for="(project, index) in draftedProjects"
                                    :key="index"
                                    cols="12"
                                    md="6"
                                    lg="4"
                                    class="mt-6"
                                >
                                    <project-info-card
                                        :project="project"
                                        :type="getProjectType(project)"
                                        card-width="372"
                                        :card-heigth="cardHeight"
                                        :media-height="mediaHeight"
                                        media-width="372"
                                        :thumbnail="
                                            project.youtube_url ? true : false
                                        "
                                        @card-click="viewProject(project)"
                                    >
                                        <template #deleteProject>
                                            <delete-project-btn
                                                :project="project"
                                                :type="getProjectType(project)"
                                                @get-projects="
                                                    handleMyProjects()
                                                "
                                            ></delete-project-btn>
                                        </template>
                                        <template #editProject>
                                            <edit-project-btn
                                                :project="project"
                                            ></edit-project-btn>
                                        </template>
                                        <template #viewAction>
                                            <view-project-btn
                                                @view-project="
                                                    viewProject(project)
                                                "
                                            ></view-project-btn>
                                        </template>
                                    </project-info-card>
                                </v-col>
                                <v-col
                                    v-if="draftedProjects.length <= 0"
                                    cols="12"
                                    class="mt-8"
                                >
                                    {{ $t("MyProjects.tabs.noDrafted") }}
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col v-if="getProjectsStatus_Error" cols="12">
                    {{ $t("messages.error.sthWrong") }}
                </v-col>
                <loading-spinner
                    :show="getProjectsStatus_Pending"
                ></loading-spinner>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { apiStatus } from "@/api/constants/apiStatus.js";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus.js";
import { getMyProjects } from "@/api/projectsApi.js";
import { CONFIG } from "@/common/config.js";
import { getRole } from "@/helpers/generalHelpers.js";
import { mapState, mapGetters } from "vuex";

import ProjectInfoCard from "@/components/InfoCard/ProjectInfoCard.vue";
import ViewProjectBtn from "@/components/InfoCard/ViewProjectBtn.vue";
import EditProjectBtn from "@/components/InfoCard/EditProjectBtn.vue";
import DeleteProjectBtn from "@/components/InfoCard/DeleteProjectBtn.vue";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import Hero from "@/components/Heroes/Hero.vue";
export default {
    name: "MyProject",

    components: {
        ProjectInfoCard,
        ViewProjectBtn,
        EditProjectBtn,
        DeleteProjectBtn,
        LoadingSpinner,
        Hero,
    },

    data() {
        return {
            tab: null,
            tabItems: [
                {
                    id: 1,
                    title: "published",
                },
                {
                    id: 2,
                    title: "drafted",
                },
            ],
            publishedProjects: [],
            draftedProjects: [],

            cardHeight: CONFIG.min_card_heigth,
            mediaHeight: CONFIG.min_media_height,
            getProjectsStatus: apiStatus.Idle,
        };
    },

    computed: {
        isInfoPage() {
            if (this.$route.params.id) {
                return true;
            }
            return false;
        },
        ...apiStatusComputed(["getProjectsStatus"]),
        ...mapState({
            user_role: (state) => state.auth.user.role,
        }),
        ...mapGetters({
            isLoggedIn: "auth/isLoggedIn",
        }),
    },

    methods: {
        async handleMyProjects() {
            this.publishedProjects = [];
            this.draftedProjects = [];
            this.getProjectsStatus = apiStatus.Pending;
            const role = getRole(this.user_role);

            if (role === "chef") {
                this.getRecipes(role);
                this.getAdvices(role);
                return;
            }

            let url = `${role}/${this.getType()}`;

            const { response, error } = await withAsync(getMyProjects, url);

            if (error) {
                this.getProjectsStatus = apiStatus.Error;
                return;
            }

            this.publishedProjects = response.data.data.filter(
                (item) => item.status == "publish"
            );
            this.draftedProjects = response.data.data.filter(
                (item) => item.status == "draft"
            );
            this.getProjectsStatus = apiStatus.Success;

            if (role === "admin") {
                this.getRecipes(role);
                this.getAdvices(role);
                this.getLibraries(role);
            }
        },

        async getRecipes(role) {
            let url = `${role}/recipes`;
            const { response, error } = await withAsync(getMyProjects, url);

            if (error) {
                this.getProjectsStatus = apiStatus.Error;
                return;
            }
            if (!response.data.data) return;
            const recipes = response.data?.data.map((recipe) => {
                return {
                    ...recipe,
                    name: recipe.title,
                    category: "recipes",
                };
            });

            if (recipes) {
                const publicRecipes = recipes.filter(
                    (recipe) => recipe.status === "publish"
                );

                this.publishedProjects = [
                    ...this.publishedProjects,
                    ...publicRecipes,
                ];

                const draftRecipes = recipes.filter(
                    (recipe) => recipe.status === "draft"
                );
                this.draftedProjects = [
                    ...this.draftedProjects,
                    ...draftRecipes,
                ];
            }

            this.getProjectsStatus = apiStatus.Success;
        },
        async getAdvices(role) {
            let url = `${role}/advices`;
            const { response, error } = await withAsync(getMyProjects, url);

            if (error) {
                this.getProjectsStatus = apiStatus.Error;
                return;
            }

            if (!response.data.data) return;
            const advices = response.data?.data.map((advice) => {
                return {
                    ...advice,
                    name: advice.title,
                    category: "nutrition",
                };
            });

            if (advices) {
                const publicAdvice = advices.filter(
                    (advice) => advice.status === "publish"
                );

                this.publishedProjects = [
                    ...this.publishedProjects,
                    ...publicAdvice,
                ];

                const draftAdvice = advices.filter(
                    (advice) => advice.status === "draft"
                );
                this.draftedProjects = [
                    ...this.draftedProjects,
                    ...draftAdvice,
                ];
            }

            this.getProjectsStatus = apiStatus.Success;
        },

        async getLibraries(role) {
            let url = `${role}/libraries`;
            const { response, error } = await withAsync(getMyProjects, url);

            if (error) {
                this.getProjectsStatus = apiStatus.Error;
                return;
            }
            if (!response.data.data) return;
            const libraries = response.data?.data.map((lib) => {
                return {
                    ...lib,
                    name: lib.title,
                    category: "libraries",
                };
            });

            if (libraries) {
                const publicLib = libraries.filter(
                    (lib) => lib.status === "publish"
                );

                this.publishedProjects = [
                    ...this.publishedProjects,
                    ...publicLib,
                ];

                const draftLib = libraries.filter(
                    (lib) => lib.status === "draft"
                );
                this.draftedProjects = [...this.draftedProjects, ...draftLib];
            }

            this.getProjectsStatus = apiStatus.Success;
        },

        viewProject(project) {
            let name;
            switch (this.getProjectType(project)) {
                case "project":
                    name =
                        project.category == "garden"
                            ? "GardenInfo"
                            : "ConstructionInfo";
                    break;
                case "recipe":
                    name = "RecipeInfo";
                    break;
                case "advice":
                    name = "AdviceInfo";
                    break;
                case "library":
                    name = "LibraryInfo";
                    break;
            }

            this.$router.push({
                name: name,
                params: { id: project.id },
            });
        },
        getType() {
            switch (getRole(this.user_role)) {
                case "user":
                    return "projects";
                case "admin":
                    return "projects";
                case "chef":
                    return "recipes";
                default:
                    return "project";
            }
        },
        getProjectType(project) {
            switch (project.category) {
                case "recipes":
                    return "recipe";
                case "nutrition":
                    return "advice";
                case "libraries":
                    return "library";
                case "garden":
                    return "project";
                case "constructions":
                    return "project";
                default:
                    return "project";
            }
        },
        register() {
            this.$router.push({ name: "Register" });
        },
    },

    created() {
        if (!this.isLoggedIn) return;
        this.handleMyProjects();
    },
};
</script>

<style lang="scss" scoped></style>
