<template>
    <v-btn icon plain small color="text" @click="openModal()">
        <v-icon size="18" color="error darken-1">
            fa-solid fa-trash-can
        </v-icon>
        <v-dialog v-model="dialog" persistent max-width="400">
            <v-card>
                <v-card-title>
                    {{ $t("MyProjects.deleteDialog.title") }}
                </v-card-title>
                <v-card-text>
                    {{ $t("MyProjects.deleteDialog.description") }}
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog = false">
                        {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                        depressed
                        color="error lighten2"
                        @click="deleteProject()"
                    >
                        {{ $t("general.delete") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-btn>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { deleteProject } from "@/api/projectsApi.js";
import { mapState } from "vuex";
export default {
    name: "EditProjectBtn",

    props: {
        project: {
            required: true,
            type: Object,
        },
        type: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            dialog: false,
        };
    },

    computed: {
        ...mapState({
            user_role: (state) => state.auth.user.role,
        }),
    },

    methods: {
        async deleteProject() {
            let url = `${this.user_role}/${this.type}/${this.project.id}`;

            const { response, error } = await withAsync(deleteProject, url);

            if (error) {
                return;
            }

            this.$emit("get-projects");
        },
        openModal() {
            this.dialog = true;
        },
    },
};
</script>
