<template>
    <v-btn icon plain small color="text" @click="editProject()">
        <v-icon size="18" color="primary darken-1">
            fa-solid fa-pen-to-square
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    name: "EditProjectBtn",

    props: {
        project: {
            required: true,
            type: Object,
        },
    },

    methods: {
        editProject() {
            const routeName = this.getType();
            this.$router.push({
                name: routeName,
                params: { id: this.project.id },
            });
        },

        getType() {
            switch (this.project.category) {
                case "recipes":
                    return "EditRecipe";
                case "nutrition":
                    return "EditAdvice";
                case "libraries":
                    return "EditLibrary";
                case "garden":
                    return "EditProject";
                case "constructions":
                    return "EditProject";
                default:
                    return "EditProject";
            }
        },
    },
};
</script>
